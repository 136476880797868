@font-face 
    font-family: 'Exo 2'
    src: url('/fonts/Exo2-Light.ttf') format('truetype')
    font-weight: 300
    font-style: normal

@font-face 
    font-family: 'Exo 2'
    src: url('/fonts/Exo2-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal
    
*
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

html,
body
    height: 100%
    min-height: 100%

body
    font-family: "Gotham Rounded A", "Gotham Rounded B"
    font-style: normal
    font-weight: normal
    font-size: 20px
    line-height: 28px
    letter-spacing: 0.025em
    color: #2c302c
    overflow-y: scroll

a
    color: #2c302c
    text-decoration: none

#app
    overflow: hidden

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol
    margin: 0

h1,
h2
    font-style: normal
    font-weight: 500
    text-transform: uppercase  

h3
    font-family: "Exo 2"
    font-weight: 500
    font-size: 1.4em
    line-height: 1.28em

ul,
ol
    padding: 0
    list-style: none

.rich
    font-size: 1em
    line-height: 1.4em

    * + h2,
    * + h3,
    * + p,
    * + ul,
    * + ol 
        margin-top: 1.05em

    ul 
        list-style-type: disc

        li 
            margin-left: 1em

            + li 
                margin-top: 0.55em

    ol 
        li
            counter-increment: count
            position: relative
            padding-left: 1.25em 

            &::before
                position: absolute
                left: 0
                top: 0
                content: counter(count) ")"
        
            + li 
                margin-top: 1.05em

    a
        color: #E7237D

        &:hover
            text-decoration: underline

.clear:after
    content: ''
    display: block
    clear: both

.LazyLoad
    opacity: 0

.LazyLoad.is-visible
    opacity: 1


.lazyImage 
    opacity: 0
    transition: opacity 0.4s ease-out

.lazyImage.loaded
    opacity: 1 

/* */

.center
    position: relative
    margin: 0 auto         

/* */

.center.main
    max-width: 1440px
    padding: 0 57px

.center.main > .inner
    position: relative

.table
    display: table
    position: relative
    width: 100%
    table-layout: fixed
    margin-bottom: 0

.table > .col
    position: relative
    display: table-cell
    width: auto
    padding: 0px
    vertical-align: top

a.area
    position: absolute
    z-index: 10
    left: 0
    top: 0
    width: 100%
    height: 100%

/* */

.navigation.main 
    font-size: 15px
    text-transform: uppercase
    letter-spacing: 0.086em

.navigation.main li
    position: relative
    display: inline-block

.navigation.main a
    display: inline-block
    padding: 0 8px
    cursor: pointer

.navigation.main a > span
    position: relative
    display: inline-block
    overflow: hidden
    padding: 0 3px

.navigation.main a > span > span:not(.text)
    position: absolute
    z-index: -1
    left: 0
    top: 100%
    background: rgba(208, 230, 210, 1)
    width: 100%
    height: 100%
    transition: all 0.2s ease-out
    -webkit-transition: all 0.2s ease-out
    -moz-transition: all 0.2s ease-out
    -o-transition: all 0.2s ease-out
    opacity: 0

.navigation.main a:hover > span > span:not(.text),
.navigation.main a.selected > span > span:not(.text),
.navigation.breadcrumb ul li:last-child a span > span
    top: 50%
    opacity: 1

.navigation.main:not(.breadcrumb) ul:first-child li:first-child a
    padding-left: 0

.navigation.main:not(.breadcrumb) ul:last-child li:last-child,
.navigation.main:not(.breadcrumb) ul:nth-child(2) li:last-child a,
.navigation.main:not(.breadcrumb) ul:nth-child(3) li + li a
    padding-right: 0



/* */

.icon.main
    display: block
    position: relative
    width: 53px
    height: 53px
    border: solid 2px #e8247e
    border-radius: 50%
    cursor: pointer

    svg
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%


    &:hover,
    &.selected 
        background: #E8247E   

        .stroke
            stroke: #fff
 

.icon.main:hover .fill
    fill: #fff
 
.icon.main:hover .fillReverse
    fill: #E8247E

.icon.main.topRight
    position: absolute
    right: 0
    top: 0 
    margin: 3.8% 3.8% 0 0

.icon.main.bottomRight
    position: absolute
    right: 0
    bottom: 0 
    margin: 0 3.8% 3.8% 0
 
.icon.main.middle
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)    

.icon.main.pdf .pdfRemove
    display: none
 
.icon.main.pdf.selected:hover .pdfActive
    display: none
 
.icon.main.pdf.selected:hover .pdfRemove
    display: block
 

/* */

.hoverArea
    position: absolute
    z-index: 2
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: #fff
    opacity: 0

/* Breadcrumb */

.navigation.breadcrumb,
.navigation.asBreadcrumb
   padding-top: 28px
   padding-bottom: 18px


.navigation.breadcrumb li a
    background-image: url(img/arrow.svg)
    background-repeat: no-repeat
    background-position: 0 7px


.navigation.breadcrumb li a
    padding: 0 11px 0px 14px


.navigation.breadcrumb li a > span
    pointer-events: none


.navigation.sub
    float: right   
    width: auto
    margin-top: -55px
    height: 55px

    ul
        float: right
/* */

.page
    position: relative


.page > div > .content,
#overlay .col > .content
    margin-bottom: 70px


.page > div .loading.test
    position: relative
    padding-bottom: 46.9079939668175% 

/* Text */

.textImage .col
    float: left
    position: relative
    width: 50%


.textImage .col:nth-child(1)
    padding-right: 3.35%


.textImage .col:nth-child(2)
    padding-left: 3.35%


.textImage .col img
    float: left
    width: 100%


.textImage .col > span
    position: absolute
    z-index: -1 


.textImage .col.text > span
    top: 0
    width: 150%
    height: 100%
    -webkit-box-sizing: content-box
    -moz-box-sizing: content-box
    box-sizing: content-box
    padding-left: 3.35%


.textImage.right .col.text > span
    left: 0%


.textImage.left .col.text > span
    right: 0px


.textImage .col.image > span
    display: none
    left: 0
    width: 100%
    height: 50%


.textImage.right .col.image > span
    top: 0


.textImage.left .col.image > span
    bottom: 0


.textImage .col.image > div > .image
    position: relative
    background: no-repeat center center
    background-size: cover


.textImage .col.image > div > .image:hover .hoverArea
    opacity: 0.6
    cursor: pointer


.textImage .col.image > div > .image .icon
    z-index: 3


.textImage .text
    padding-top: 6%
    padding-bottom: 6.7%


.textImage.right .text
    padding-left: 6.7%


.textImage.left .text
    padding-right: 6.7%


/* MultiGallery */

.multiGallery .grid
    position: relative


.multiGallery .grid .image
    position: absolute
    cursor: pointer
    overflow: hidden


.multiGallery .grid .image img
    position: absolute
    z-index: 1
    left: 0
    top: 0
    width: 100%
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940)
    transition-duration: 0.4s 

.multiGallery .grid .image video
    position: absolute
    z-index: 2
    left: 0
    top: 0
    width: 100%
    height: 100%
    object-fit: cover


.multiGallery .grid .image:hover img
    transform: scale(1.06)
    transition-duration: 0.8s 


.multiGallery .grid .image:hover .hoverArea,
.multiGallery .grid .image.touched .hoverArea
    opacity: 0.2


.multiGallery .grid .image .icon
    z-index: 3
    opacity: 0
    display: none 


.multiGallery .grid .image:hover .icon,
.multiGallery .grid .image span.pdf.selected,
.multiGallery .grid .image.touched .icon
    opacity: 1 
    display: block  


/* */

.content.text .col > div
    position: relative
    padding: 7.541478129713423831070889894419%
    word-wrap:break-word


.content.text.cols2 .col
    float: left
    width: 50%


.content.text.cols2 .col > div
    position: relative
    padding: 15.982956259426847662141779788839%


.content.text.cols2 .col:nth-child(1)
    padding-right: 3.35%
 

.content.text.cols2 .col:nth-child(2)
    padding-left: 3.35%
 

/* Gallery */

.gallery .inner
     padding-top: 80px  


.gallery .fullscreen
    position: absolute
    right: 0
    top: 29px
    padding-right: 20px
    background: url(img/arrow2.svg) no-repeat right 5px
    background-size: 11px 11px
    font-size: 15px
    line-height: 22px
    text-transform: uppercase
    cursor: pointer


.gallery .media
    position: relative
    overflow: hidden    


.gallery .media ul
    position: relative
    float: left
    width: 100%
    padding-bottom: 66%
    margin-bottom: 70px


.gallery .media ul li
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    transition: all 0.4s ease-out
    -webkit-transition: all 0.4s ease-out
    -moz-transition: all 0.4s ease-out
    -o-transition: all 0.4s ease-out


.gallery .media ul li p
    position: absolute
    width: 100%
    left: 0
    bottom: -108px
    font-size: 20px
    line-height: 28px
    text-align: center
    display: none


.gallery .inner > ul
    position: absolute
    width: 100%
    left: 0
    bottom: 25px
    text-align: center


.gallery .inner > ul > li
    position: relative
    display: inline-block
    width: 19px
    height: 19px
    cursor: pointer


.gallery .inner > ul > li > span
    position: absolute
    width: 9px
    height: 9px
    border-radius: 50%
    background: #fff


.gallery .inner > ul > li.selected span
    background: #2c302c


.gallery.content .pdf
    position: absolute
    right: 0
    bottom: 12px


/* */

.pdfForm .images 
   position: relative
   overflow: hidden


.pdfForm .images div:hover > .hoverArea  
    opacity: 0.6
 

.pdfForm .images div .icon
    position: absolute
    z-index: 3
    opacity: 0


.pdfForm .images div:hover > .icon
    opacity: 1


.pdfForm .images div .pdf .pdfRemove
    display: block


.pdfForm .text
    padding: 4% 0 5% 0


.pdfForm .text p
    font-family: 'Exo 2', sans-serif
    font-size: 28px
    text-align: center


.pdfForm .form
    background-color: #e6e5e0
    padding: 7.142857142857142857142857142857% 7.518796992481203007518796992481% 7% 7.518796992481203007518796992481% 


.pdfForm .form label
    display: block
    margin-bottom: 16px
    font-size: 20px
    font-weight: 500
    text-transform: uppercase


.pdfForm .form input + label
    margin-top: 2.631578947368421052631578947368% 


.pdfForm .form input[type="text"],
.pdfForm .form textarea
    width: 100%
    padding: 0 19px
    border: none
    background: #fff
    font-family: "Gotham Rounded A", "Gotham Rounded B"
    font-size: 20px
    font-weight: normal


.pdfForm .form input[type="text"]
    height: 56px


.pdfForm .form textarea
    height: 168px
    padding-top: 14px
    margin-top: 10px
    resize: none


.pdfForm .form .privacy
    margin-top: 14px  


.pdfForm .form .privacy label
    position: relative
    padding-left: 20px
    font-size: 15px
    line-height: 18px
    text-transform: none
    font-weight: normal


.pdfForm .form .privacy.error label,
.pdfForm .form .privacy.error label a
    color: #E8247E


.pdfForm .form .privacy label input
    position: absolute
    left: 0
    top: 0


.pdfForm .submit
    text-align: center


.pdfForm .submit .icon
    margin-top: 4.6%
    display: inline-block


/* */

.content.team .grid
    position: relative


.content.team .grid > div
    position: absolute


.content.team .person .image
    position: relative
    padding-bottom: 100%


.content.team .person .image img
    position: absolute
    left: 0
    top: 0
    width: 100%


.content.team .person .image .hover
    position: absolute
    z-index: 2
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: #dae4ea   
    transition: all 0.4s ease-out
    -webkit-transition: all 0.4s ease-out
    -moz-transition: all 0.3s ease-out
    -o-transition: all 0.4s ease-out
    opacity: 0
    word-wrap:break-word
    pointer-events: none


.content.team .person:hover .image .hover,
.content.team .person.touched .image .hover
    opacity: 1


.content.team .person .image .hover .table
    height: 100%


.content.team .person .image .hover .table .col
    padding: 0 10px
    vertical-align: middle
    text-align: center


.content.team .person .image .hover p
    font-size: 28px
    margin-bottom: 11px


.content.team .person .image .hover a
    font-size: 15px
    font-weight: normal
    text-transform: uppercase
    pointer-events: auto


.content.team .person h4
    margin-top: 24px
    font-weight: 500
    text-align: center
    text-transform: uppercase


.content.team .grid > .image 
    background: no-repeat center center
    background-size: cover


/* */


.gallery .button
    position: absolute
    z-index: 10
    top: 50%


.content.gallery .button
    margin-top: -83px

.gallery .button.previous  
    -moz-transform: rotate(180deg)
    -ms-transform: rotate(180deg)
    -o-transform: rotate(180deg)
    -webkit-transform: rotate(180deg)
    transform: rotate(180deg)

.gallery .button.next
    right: 0

.content.gallery .button.previous 
    left: 25px

.content.gallery .button.next 
    right: 25px


/* */

.aniHover
    transition: all 0.2s ease-out
    -webkit-transition: all 0.2s ease-out
    -moz-transition: all 0.2s ease-out
    -o-transition: all 0.2s ease-out


.aniResize
    transition: all 0.3s ease-out
    -webkit-transition: all 0.3s ease-out
    -moz-transition: all 0.3s ease-out
    -o-transition: all 0.3s ease-out


@media screen and ( max-width: 1440px )

    .center.main
        padding: 0 3.958333333333333333333333333333%

    .page > div > .content,
    #overlay .col > .content
        margin-bottom: 4.86% 
    

    .teaser .table + .table
        margin-top: 5.69%
    

@media screen and ( max-width: 767px )
    .navigation.breadcrumb
        padding: 0 0 5.2% 0
    

    .navigation.breadcrumb ul
        display: none

    .navigation.sub
        float: none 
        margin-top: -1.8%
    

@media screen and ( max-width: 663px )
    .center.main
        padding: 0 25px
    

@media screen and ( max-width: 564px )

    .page > div > .content,
    #overlay .col > .content
        margin-bottom: 25px
       
    
@media screen and ( max-width: 480px )  

    .navigation.main.asBreadcrumb li
        float: left
        width: 50%
    

    .navigation.main.asBreadcrumb li a
        display: block
        width: 100%
        text-align: center
    


    /* */
    
    .teaser.cols2 .text
        padding: 4.2% 5%
    

    .teaser.cols2 .text p
        padding-bottom: 3%
    

    .teaser .col + .col
        margin-top: 5.69%
    

    /* */

    .textImage .center.main
        padding: 0
    

    .textImage .col:nth-child(1),
    .textImage .col:nth-child(2)
        width: 100%
        padding-left: 25px
        padding-right: 25px
    

    .textImage .col.image > span
        display: block
    

    .textImage h3
        text-align: center
    


/* */

.fade2-appear,
.fade2-enter
    opacity: 0


.fade2-appear-active,
.fade2-enter-active
  transition: opacity .2s linear
  opacity: 1


.fade2-exit
    transition: opacity .1s linear
    opacity: 1


.fade2-exit-active
  opacity: 0


/* */

.fade-appear,
.fade-enter
    z-index: 2
    opacity: 0


.fade-appear-active,
.fade-enter-active
  transition: opacity .2s linear
  opacity: 1


.fade-exit
    z-index: 1
    transition: opacity .1s linear
    opacity: 1


.fade-exit-active
  opacity: 0


/* */

#page
    transition: transform .4s cubic-bezier(0.190, 1.000, 0.220, 1.000)

    &.test
        transform: scale(0.7)

    > div:first-child
        min-height: 100vh

/* */  

.fadeIn
    transition: opacity 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940)
    opacity: 0   

    &.delay
        transition-delay: 0.3s

    &.fromBottom
        transition-property: opacity, transform
        transform: translateY(5%)

    &.show
        opacity: 1

        &.fromBottom
            transform: none

.textfadeIn
    transition: opacity 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940)
    transition-delay: 0.25s
    opacity: 0
    
    &.fromBottom
        transition-property: opacity, transform
        transform: translateY(50%)

    &.delay
        transition-delay: 0.5s

.show
    .textfadeIn
        opacity: 1
    
        &.fromBottom
            transform: none



