.images
    \:global

        h2
            text-align: center
            margin-bottom: 4.86%

        .images
            position: relative

          


            img 
               width: 100%