.contact
    \:global

        h4 
            margin: 0 0 6% 0
            font-style: normal
            font-weight: 500
            text-transform: uppercase
            color: #E8247E

        .inner > h4
            display: none

        .table
           background: #FBF1F3

        

        .image 
            padding-bottom: 50%  

            > div
                position: absolute
                left: 0
                top: 0
                width: 100%
                height: 100%
                background-size: cover
                background-position: center center
                             
        .text
                padding: 4.07% 6% 6% 6.1%
                vertical-align: middle

                p
                    font-family: 'Exo 2', sans-serif
                
                a
                    position: absolute
                    right: 6%
                    bottom: 6%
                    color: #E8247E
                    text-transform: uppercase

                    .icon
                        float: right
                        margin-left: 24px

                    &:hover
                        .icon
                            background: #E8247E   

                            .stroke
                                stroke: #fff 

        @media screen and ( max-width: 580px )
            background: #FBF1F3
            padding-top: 25px

            .inner > h4
                display: block
                

            .table
                .col
                    display: block
                  
                .image
                    padding-bottom: 0

                .image > div
                    position: relative
                    padding-bottom: 100%

                .text
                    padding: 6% 6% 15% 0

                    h4
                        display: none

          
                