.root
    display: inline-flex
    line-height: 1.75
    cursor: pointer
    align-items: center

    svg
        margin-right: 10px
        width: 18px

    path:nth-child(1)
        display: none

    &:global(.active)
        path:nth-child(1)
            display: block

        path:nth-child(2)
            display: none
    
    &:global(.reverse)
        flex-direction: row-reverse
        justify-content: space-between
        align-items: flex-start
        margin-top: 40px

        svg
            margin-left: 0

        div
            flex: 0 0 calc(100% - 40px)
            text-transform: none

    & + &
        margin-left: 40px