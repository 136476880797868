@import "css/index.sass"

.text
    \:global
        h2
            width: 100%
            text-align: center
            margin-bottom: 4.36%

        .flex
            display: flex  

            .col
                position: relative
                z-index: 2
                flex: 0 0 50%
                padding: 4.07% 6.94%

                &.overlap
                    z-index: 1
         
            &.overlap
                align-items: flex-start
                   
            .bg
                position: absolute
                z-index: -1
                top: 0
                width: 100%
                height: 100%

            .col:nth-child(1)
                .bg
                    left: 0

            .col:nth-child(2)
                .bg
                    right: 0
                    
            .overlap > .bg
                width: 160%

        .cols1
            padding: 0 6.94%

        h5
            font-weight: 500

        .rich
            font-family: 'Exo 2', sans-serif
            font-weight: 300

        @media screen and ( max-width: $media-s )

            .center.main
                padding: 0

            .flex
                flex-wrap: wrap

                .col
                    flex: 0 0 100%
                    padding: 4.07% 25px

                .overlap
                     width: 100%

               
                