.teaser
    \:global

        .flex
            display: flex
            justify-content: space-between

            > .col
                flex: 0 0 32.3%
                padding-bottom: 46.9%
                transform: translateY(7%)
                transition: 1.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)
                transition-property: opacity, transform
                opacity: 0
                overflow: hidden

                + .col
                    transition-delay: 0.3s

                    + .col
                        transition-delay: 0.6s

                &:hover
                    span.icon
                        background: #E8247E   

                        svg
                            stroke: #fff

        .image 
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background-size: cover
            background-position: center center
            transition: transform 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940)
            will-change: transform

        .col:hover 
                .image
                    transform: scale(1.05)

        .text 
            position: absolute
            left: 0%
            bottom: 0px
            width: 100%

            .icon
                position: absolute
                bottom: 0
                margin-bottom: 9.29%
                right: 4.08%

        h2 
            position: absolute
            bottom: 0
            margin-bottom: 9.29%
            left: 4.08%
            color: #fff

            > span
                background-image: linear-gradient(to right, #fff 0%, #fff 100%)
                background-repeat: repeat-x 
                background-position: 0 95% 
                background-size: 100% 2px 

        @media screen and ( max-width: 580px )

            .flex
                flex-wrap: wrap

                > .col
                    flex: 0 0 100%
                    display: block
                    padding-bottom: 140.74%
                    
                    + .col
                        border-left: none
                        margin-top: 10px

    &:global(.test)
        \:global 
            .flex
                > .col
                    transform: translateY(0)
                    opacity: 1




