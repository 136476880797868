@mixin animation($property: all, $duration: 500ms)
    transition: $property $duration cubic-bezier(0.250, 0.460, 0.450, 0.940)

.root
    position: relative
    width: 150px
    font-size: 0.9375rem

    &:global(.medium)
        width: 100px

    &:global(.large)
        width: 162px

    > span
        display: block
        height: 32px
        border: 1px solid var(--color-highlight)
        border-radius: 2px
        padding-left: 13px
        padding-right: 19px
        font-size: 0.9375rem
        line-height: 28px
        cursor: pointer
        overflow: hidden 
        text-overflow: ellipsis
        white-space: nowrap

        @at-root :global(.active)#{&}
            border-bottom-left-radius: 0
            border-bottom-right-radius: 0

       
        svg
            position: absolute
            right: 8px
            top: 13px
            height: 5px
            transform: rotate(180deg)     

            @at-root :global(.active)#{&}
                transform: rotate(0deg)

    > div
        position: absolute
        z-index: 10
        width: 100%
        padding: 0 13px 10px 13px
        background: #fff
        border: 1px solid var(--color-highlight)
        border-radius: 2px
        border-top-left-radius: 0
        border-top-right-radius: 0

        :global(.theme-light) &
            background: #E5D8CF

        li  
            display: flex
            align-items: center
            position: relative
            height: 21px
            border-bottom: 1px solid var(--colorGrayMedium)
            cursor: pointer

            svg
                margin-right: 10px
                width: 18px
        
                path:nth-child(1)
                    display: none

            &:global(.active)
                path:nth-child(1)
                    display: block

                path:nth-child(2)
                    display: none

        > button
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            height: 30px
            margin-top: 15px
            padding-top: 1px
            background: var(--colorGrayMedium)
            font-size: 0.75rem
            font-weight: 700
            text-transform: uppercase
            color: var(--colorWhite)

            @media (hover: hover)
                &:hover
                    background: var(--colorGrayDark)

        \:global
            .scroll
                .container
                    max-height: 205px

                .bar
                    margin-left: 6px

            .search
                position: relative
                margin-bottom: 15px

                input
                    display: block
                    width: 100%
                    height: 26px
                    padding: 2px 0 0 22px
                    border: 1px solid var(--color-blackPremium)
                    font-size: 0.625rem
                    color: var(--colorGrayNeutral)
                    outline: none

                    &::-webkit-input-placeholder,
                    &:-moz-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder,
                    &::placeholder
                        color: var(--colorGrayNeutral)
                        opacity: 1

                svg
                    position: absolute
                    width: 13px
                    left: 5px
                    top: 6px
