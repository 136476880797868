@import "css/index.sass"

.header
    \:global
        .inner 
            > div
                display: flex
                flex-wrap: wrap
                justify-content: space-between

                > div
                    flex: 0 0 50%

                > .image
                    position: relative    

                > .image > div
                    padding-bottom: 56.39%

                > .image > div div
                        position: absolute
                        left: 0
                        top: 0
                        width: 100%
                        height: 100%
                        background-size: cover
                        background-position: center center

                > .text
                    align-self: center
                    padding: 5% 3.35% 5% 3.6%

                    .logo
                        display: inline-block
                        width: 100%
                        max-width: 25%
                        margin-bottom: 5%

                        span
                            display: block
                            background-size: contain
                            background-repeat: no-repeat
                    
                    h1 + .rich 
                        margin-top: 3%

                    p
                        font-family: 'Exo 2', sans-serif
                        font-weight: 300

        .gallery
            position: absolute
            z-index: 10
            right: 0
            bottom: -53px
            padding-right: 20px
            background: url(/static/media/arrow2.393ffd46.svg) no-repeat right 5px
            background-size: 11px 11px
            font-size: 15px
            line-height: 22px
            text-transform: uppercase
            cursor: pointer
            color: #000

        @media screen and ( max-width: 767px )
            .gallery
                position: relative
                float: right
                bottom: inherit
                margin-top: 30px
                margin-bottom: -10px           

        @media screen and ( max-width: $media-s )
            .center.main
                padding: 0

                .inner 
                    > div
                        > div
                            flex: 0 0 100%

                            > div
                                margin: 0 25px

                        > .image
                                background: linear-gradient(180deg,#fff 0,#fff 49.999999999%,hsla(0,0%,100%,0) 50%,hsla(0,0%,100%,0))

                        > .image > div
                                position: relative

                        > .text 
                            text-align: center
                            padding: 5% 0 10% 0 

                .gallery
                    margin-right: 25px


                    

