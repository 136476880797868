.root 
    position: fixed
    z-index: 110
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    color: #fff
    font-size: 1rem
    line-height: 1

    &::before
       position: absolute
       left: 0
       top: 0
       width: 100%
       height: 100%
       background: rgba(0,0,0,0.6)
       content: "" 

    > div
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        background: black
        padding-top: 20px
        padding-bottom: 20px
        overflow: auto
        max-height: 400px

    \:global
        h6
            font-size: 2rem
            margin-bottom: 1rem

        button
            height: 41px
            padding: 0 10px
            border-radius: 5px
            border: none
            font-family: "Gotham Rounded A", "Gotham Rounded B"
            cursor: pointer

            &.save-all
                background-color: #E8247E
                color: #fff

        .action
            display: inline-block
            color: #E8247E
            cursor: pointer
   
        .intro
            display: flex
            flex-direction: column

            @at-root:global(.overview)#{&}
                @media screen and ( min-width: 768px )
                    flex-direction: row

            > div + div
                @at-root:global(.overview)#{&}
                    @media screen and ( min-width: 768px )
                        flex: 0 0 200px
                        margin-left: 20px

                    @media screen and ( max-width: 767px )
                        margin-top: 20px

                    button 
                        width: 100%

                        + button
                            margin-top: 20px

                @at-root:global(.settings)#{&}
                    display: flex
                    margin-top: 20px

                    button + button
                        margin-left: 20px

               

            .back       
                margin-right: 0
                margin-left: auto

            .types
                margin-top: 20px

        .links
            margin-top: 15px
            font-size: 0.75rem
            text-align: center

            > * 
                margin: 5px

            > a 
                color: #bdc1c8

        .settings
            margin-top: 20px

            .type
                position: relative
                font-size: 0.75rem
                line-height: 1.5

                + .type
                    margin-top: 20px


                .title
                    display: block
                    margin-bottom: 10px
                    font-size: 1.25rem

                > .action
                    margin-top: 10px

                .switch
                    position: absolute
                    right: 0
                    top: 0
                    width: 50px
                    height: 26px
                    background-color: #bdc1c8
                    border-radius: 13px
                    cursor: pointer

                    > span
                        position: absolute
                        right: calc(100% + 10px)
                        top: 0
                        line-height: 26px
                        color: #fff

                    &::before
                        position: absolute
                        left: 3px
                        top: 3px
                        display: block
                        width: 20px
                        height: 20px
                        background: #fff
                        border-radius: 100%
                        content: ''

                    &.active
                        &::before
                            left: 27px
                            background-color: #E8247E
                        
                .cookie-type
                    margin-top: 10px
                   

                    > div
                        display: flex

                        + div
                            margin-top: 10px

                        > div
                            flex: 0 0 40%

                            + div
                                flex: 0 0 60%

                            a
                               color: #E8247E 