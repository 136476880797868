@import "css/index.sass"
    
.text
    \:global
        h2
            width: 100%
            text-align: center
            margin-bottom: 4.36%

        .test
            display: flex
            font-family: 'Exo 2', sans-serif
            font-weight: 300
            
            .col
                position: relative
                flex: 0 0 50%
                
                &.text
                    padding: 4.07% 6.94%

                &.image 
                    background-size: cover
                    background-position: center center

                &.image > div
                    background-size: contain
         
            &.overlap
                align-items: flex-start

            &.reverse
                flex-direction: row-reverse  

            .bg
                position: absolute
                z-index: -1
                top: 0
                width: 100%
                height: 100%

            .col:nth-child(1)
                .bg
                    left: 0

            .col:nth-child(2)
                .bg
                    right: 0
                    
            .overlap
                z-index: -2
                width: 160%

            h5
                font-weight: 500

        @media screen and ( max-width: $media-s )

            .test
                flex-wrap: wrap

                &.reverse
                    flex-wrap: wrap-reverse

                .col
                    flex: 0 0 100%

                .overlap
                     width: 100%

               
                